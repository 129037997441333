body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.mapboxgl-ctrl-scale {
    border-style: none solid solid;
    border-color: #757575 !important;
    border-width: 1px;
    line-height: 16px;
  }

/* .geocoder {
    position: absolute !important;
    z-index: 1 !important;
    width: 50% !important;
    left: 50% !important;
    margin-left: -25% !important;
    top: 10px !important;
}

.mapboxgl-ctrl-geocoder {
    min-width: 100% !important;
}

.geocoder-dropdown-item {
    padding: 5px !important;
}

.geocoder-dropdown-icon {
    margin-right: 10px !important;
}

.geocoder-dropdown-text {
    color: #5a3fc0 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: larger !important;
} */
